<template>
  <div>
    <v-row dense class="pa-0 mt-10 mx-0">
      <v-col cols="12" class="d-flex justify-start align-center">
        <v-btn-toggle
          v-model="btnRenderSide"
          borderless
          color="primary"
          @change="transformRender(btnRenderSide)"
        >
          <v-btn class="subtitle-1 font-weight-bold"> Original View </v-btn>
          <v-btn class="subtitle-1 font-weight-bold"> Flip Horizontally </v-btn>
          <v-btn class="subtitle-1 font-weight-bold"> Flip Vertically </v-btn>
        </v-btn-toggle>
        <v-spacer />
        <v-btn-toggle
          v-model="btnRenderLayer"
          borderless
          @change="transformLayer(btnRenderLayer)"
        >
          <v-btn class="subtitle-1 font-weight-bold"> Top View </v-btn>
          <v-btn class="subtitle-1 font-weight-bold"> Bottom View </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row dense class="pa-0 mt-5 mx-0">
      <v-col cols="12" md="3">
        <v-row dense class="mr-5">
          <v-col cols="12" md="6">
            <v-btn
              block
              color="secondary"
              @click="allLayersToggler({ isShown: true })"
              :disabled="
                shownAllTop &&
                  shownAllBottom &&
                  shownAllList(showMechanicalLayer) &&
                  shownAllFF
              "
            >
              Show All Layers</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              block
              color="secondary"
              @click="allLayersToggler({ isShown: false })"
              :disabled="
                hiddenAllTop &&
                  hiddenAllBottom &&
                  hiddenAllList(showMechanicalLayer) &&
                  hiddenAllFF
              "
            >
              Hide All Layers</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12" md="4" class="d-flex align-center">
            <span class="font-weight-medium">Top</span>
          </v-col>
          <v-col cols="12" md="8" class="d-flex align-center justify-end">
            <v-btn
              text
              color="secondary"
              @click="svgToggler({ layer: 'Top', isShown: true })"
              :disabled="shownAllTop"
            >
              Show All</v-btn
            >
            <v-btn
              text
              color="secondary"
              @click="svgToggler({ layer: 'Top', isShown: false })"
              :disabled="hiddenAllTop"
            >
              Hide All</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              single-line
              :append-icon="showTopCopper ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showTopCopper ? true : false"
              :background-color="showTopCopper ? '#07568e' : ''"
              :value="
                topCopperContainer
                  ? `copper - ${topCopperContainer.file}`
                  : `copper`
              "
              @click:append="showTopCopper = !showTopCopper"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              :append-icon="showTopSoldermask ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showTopSoldermask ? true : false"
              :background-color="showTopSoldermask ? '#e2b309' : ''"
              :value="
                topSoldermaskContainer
                  ? `soldermask - ${topSoldermaskContainer.file}`
                  : `soldermask`
              "
              @click:append="showTopSoldermask = !showTopSoldermask"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              :append-icon="showTopSilkscreen ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showTopSilkscreen ? true : false"
              :background-color="showTopSilkscreen ? '#8b9e0f' : ''"
              :value="
                topSilkscreenContainer
                  ? `silkscreen - ${topSilkscreenContainer.file}`
                  : `silkscreen`
              "
              @click:append="showTopSilkscreen = !showTopSilkscreen"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mr-5 mt-5">
          <v-col cols="12" md="4" class="d-flex align-center">
            <span class="font-weight-medium">Bottom</span>
          </v-col>
          <v-col cols="12" md="8" class="d-flex align-center justify-end">
            <v-btn
              text
              color="secondary"
              @click="svgToggler({ layer: 'Bottom', isShown: true })"
              :disabled="shownAllBottom"
            >
              Show All</v-btn
            >
            <v-btn
              text
              color="secondary"
              @click="svgToggler({ layer: 'Bottom', isShown: false })"
              :disabled="hiddenAllBottom"
            >
              Hide All</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              :append-icon="showBottomCopper ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showBottomCopper ? true : false"
              :background-color="showBottomCopper ? '#c315ea' : ''"
              :value="
                bottomCopperContainer
                  ? `copper - ${bottomCopperContainer.file}`
                  : `copper`
              "
              @click:append="showBottomCopper = !showBottomCopper"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              :append-icon="showBottomSoldermask ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showBottomSoldermask ? true : false"
              :background-color="showBottomSoldermask ? '#b50418' : ''"
              :value="
                bottomSoldermaskContainer
                  ? `soldermask - ${bottomSoldermaskContainer.file}`
                  : `soldermask`
              "
              @click:append="showBottomSoldermask = !showBottomSoldermask"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mr-5">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              :append-icon="showBottomSilkscreen ? 'mdi-eye' : 'mdi-eye-off'"
              :dark="showBottomSilkscreen ? true : false"
              :background-color="showBottomSilkscreen ? '#08888e' : ''"
              :value="
                bottomSilkscreenContainer
                  ? `silkscreen - ${bottomSilkscreenContainer.file}`
                  : `silkscreen`
              "
              @click:append="showBottomSilkscreen = !showBottomSilkscreen"
              class="subtitle-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="allMechanicalContainer.length > 0">
          <v-row dense class="mr-5 mt-5">
            <v-col cols="12" md="4" class="d-flex align-center">
              <span class="font-weight-medium">Mechanical</span>
              <span v-if="allMechanicalContainer.length > 3" class="ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        showAllMechanicalLayers = !showAllMechanicalLayers
                      "
                      v-bind="attrs"
                      v-on="on"
                      >{{
                        `mdi-chevron-${showAllMechanicalLayers ? "up" : "down"}`
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    `${
                      showAllMechanicalLayers ? "Collapse" : "Expand"
                    } List of Mechanical Layers`
                  }}</span>
                </v-tooltip></span
              >
            </v-col>
            <v-col cols="12" md="8" class="d-flex align-center justify-end">
              <v-btn
                text
                color="secondary"
                @click="
                  mechanicalToggler({ layer: 'MechanicalLayer', isShown: true })
                "
                :disabled="shownAllList(showMechanicalLayer)"
              >
                Show All</v-btn
              >
              <v-btn
                text
                color="secondary"
                @click="
                  mechanicalToggler({
                    layer: 'MechanicalLayer',
                    isShown: false,
                  })
                "
                :disabled="hiddenAllList(showMechanicalLayer)"
              >
                Hide All</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense class="mr-5">
            <v-col cols="12">
              <v-text-field
                v-for="(mechanical, index) in allMechanicalContainer.slice(
                  0,
                  3
                )"
                :key="index"
                dense
                outlined
                readonly
                hide-details
                :append-icon="
                  toggleIcon({ props: 'showMechanicalLayer', index })
                "
                :dark="toggleDark({ props: 'showMechanicalLayer', index })"
                :background-color="
                  toggleColor({ props: 'showMechanicalLayer', index })
                "
                :value="
                  `${mechanical.type} - ${mechanical.filename.split('?')[0]}`
                "
                @click:append="
                  toggleMechanical({ props: 'showMechanicalLayer', index })
                "
                class="subtitle-2 mb-2"
              ></v-text-field>
              <div v-if="showAllMechanicalLayers">
                <v-text-field
                  v-for="(mechanical, index) in allMechanicalContainer.slice(3)"
                  :key="index"
                  dense
                  outlined
                  readonly
                  hide-details
                  :append-icon="
                    toggleIcon({
                      props: 'showMechanicalLayer',
                      index: index + 3,
                    })
                  "
                  :dark="
                    toggleDark({
                      props: 'showMechanicalLayer',
                      index: index + 3,
                    })
                  "
                  :background-color="
                    toggleColor({
                      props: 'showMechanicalLayer',
                      index: index + 3,
                    })
                  "
                  :value="
                    `${mechanical.type} - ${mechanical.filename.split('?')[0]}`
                  "
                  @click:append="
                    toggleMechanical({
                      props: 'showMechanicalLayer',
                      index: index + 3,
                    })
                  "
                  class="subtitle-2 mb-2"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="allDrillContainer.length > 0">
          <v-row dense class="mr-5 mt-5">
            <v-col cols="12" md="4" class="d-flex align-center">
              <span class="font-weight-medium">Drill</span>
              <span v-if="allDrillContainer.length > 3" class="ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showAllDrillLayers = !showAllDrillLayers"
                      v-bind="attrs"
                      v-on="on"
                      >{{
                        `mdi-chevron-${showAllDrillLayers ? "up" : "down"}`
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    `${
                      showAllDrillLayers ? "Collapse" : "Expand"
                    } List of Drill Layers`
                  }}</span>
                </v-tooltip></span
              >
            </v-col>
            <v-col cols="12" md="8" class="d-flex align-center justify-end">
              <v-btn
                text
                color="secondary"
                @click="drillToggler({ layer: 'DrillLayer', isShown: true })"
                :disabled="shownAllList(showDrillLayer)"
              >
                Show All</v-btn
              >
              <v-btn
                text
                color="secondary"
                @click="
                  drillToggler({
                    layer: 'DrillLayer',
                    isShown: false,
                  })
                "
                :disabled="hiddenAllList(showDrillLayer)"
              >
                Hide All</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense class="mr-5">
            <v-col cols="12">
              <v-text-field
                v-for="(drill, index) in allDrillContainer.slice(0, 3)"
                :key="index"
                dense
                outlined
                readonly
                hide-details
                :append-icon="toggleIcon({ props: 'showDrillLayer', index })"
                :dark="toggleDark({ props: 'showDrillLayer', index })"
                :background-color="
                  toggleColor({ props: 'showDrillLayer', index })
                "
                :value="`${drill.type} - ${drill.filename.split('?')[0]}`"
                @click:append="
                  toggleMechanical({ props: 'showDrillLayer', index })
                "
                class="subtitle-2 mb-2"
              ></v-text-field>
              <div v-if="showAllDrillLayers">
                <v-text-field
                  v-for="(drill, index) in allDrillContainer.slice(3)"
                  :key="index"
                  dense
                  outlined
                  readonly
                  hide-details
                  :append-icon="
                    toggleIcon({ props: 'showDrillLayer', index: index + 3 })
                  "
                  :dark="
                    toggleDark({ props: 'showDrillLayer', index: index + 3 })
                  "
                  :background-color="
                    toggleColor({ props: 'showDrillLayer', index: index + 3 })
                  "
                  :value="`${drill.type} - ${drill.filename.split('?')[0]}`"
                  @click:append="
                    toggleMechanical({
                      props: 'showDrillLayer',
                      index: index + 3,
                    })
                  "
                  class="subtitle-2 mb-2"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="testPoints.length > 0">
          <v-row dense class="mr-5 mt-5">
            <v-col cols="12" md="4" class="d-flex align-center">
              <span class="font-weight-medium">FixturFab</span>
            </v-col>
            <v-col cols="12" md="8" class="d-flex align-center justify-end">
              <v-btn
                text
                color="secondary"
                @click="ffToggler({ layer: 'TestPoints', isShown: true })"
                :disabled="shownAllFF"
              >
                Show All</v-btn
              >
              <v-btn
                text
                color="secondary"
                @click="ffToggler({ layer: 'TestPoints', isShown: false })"
                :disabled="hiddenAllFF"
              >
                Hide All</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense class="mr-5">
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                readonly
                hide-details
                :append-icon="showTestPoints ? 'mdi-eye' : 'mdi-eye-off'"
                :dark="showTestPoints ? true : false"
                :background-color="showTestPoints ? '#002F36' : ''"
                value="TestPoints"
                @click:append="showTestPoints = !showTestPoints"
                class="subtitle-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="9">
        <v-sheet class="align-center justify-center">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            fill-rule="evenodd"
            clip-rule="evenodd"
            id="top"
            viewBox="0 0 0 0"
            ref="TopSVG"
          >
            <g
              fill="#07568e"
              fill-opacity="50%"
              stroke="#07568e"
              id="topCopperContainer"
              ref="TopCopper"
              v-show="topCopperContainer && showTopCopper"
            ></g>
            <g
              fill="#e2b309"
              fill-opacity="30%"
              stroke="#e2b309"
              stroke-opacity="30%"
              ref="TopSoldermask"
              v-show="topSoldermaskContainer && showTopSoldermask"
            />
            <g
              fill="#8b9e0f"
              stroke="#8b9e0f"
              ref="TopSilkscreen"
              v-show="topSilkscreenContainer && showTopSilkscreen"
            />
            <g
              fill="#c315ea"
              fill-opacity="50%"
              stroke="#c315ea"
              ref="BottomCopper"
              v-show="bottomCopperContainer && showBottomCopper"
            />
            <g
              fill="#b50418"
              fill-opacity="50%"
              stroke="#b50418"
              stroke-opacity="50%"
              ref="BottomSoldermask"
              v-show="bottomSoldermaskContainer && showBottomSoldermask"
            />
            <g
              fill="#08888e"
              stroke="#08888e"
              ref="BottomSilkscreen"
              v-show="bottomSilkscreenContainer && showBottomSilkscreen"
            />
            <g
              v-for="(mechanical, index) in allMechanicalContainer"
              :key="index"
              :fill="getMechanicalColor(index)"
              :stroke="getMechanicalColor(index)"
              ref="allMechanicalContainer"
              v-show="getVShow({ index, layer: 'Mechanical' })"
            />
            <g
              v-for="(drill, index) in allDrillContainer"
              :key="`drill${index}`"
              :fill="getMechanicalColor(index)"
              :stroke="getMechanicalColor(index)"
              ref="allDrillContainer"
              v-show="getVShow({ index, layer: 'Drill' })"
            />
            <TestPointTop
              ref="testpointTop"
              :testpointPlot="testPoints"
              :scale="scale"
              :config2d="config2d"
              :xMultiplier="xMultiplier"
              :yMultiplier="yMultiplier"
              v-show="testPoints.length > 0 && showTestPoints"
              :transformAtt="
                gerberConfig.transformAtt
                  ? gerberConfig.transformAtt
                  : gerberConfig.transform
              "
              :gerberConfig="gerberConfig"
              @toggleHoverText="toggleHoverText"
            />
          </svg>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" color="primary" centered max-width="800">
      <v-container class="title py-0">
        <v-row dense>
          <v-col class="d-flex justify-center">
            <div>
              {{ hoverText }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "GerberLayers",
  props: {
    topCopperContainer: {
      type: Object,
      default: null,
    },
    topSoldermaskContainer: {
      type: Object,
      default: null,
    },
    topSilkscreenContainer: {
      type: Object,
      default: null,
    },
    bottomCopperContainer: {
      type: Object,
      default: null,
    },
    bottomSoldermaskContainer: {
      type: Object,
      default: null,
    },
    bottomSilkscreenContainer: {
      type: Object,
      default: null,
    },
    allMechanicalContainer: {
      type: Array,
      default: () => [],
    },
    allDrillContainer: {
      type: Array,
      default: () => [],
    },
    testPoints: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TestPointTop: () =>
      import("@/modules/configure/components/TestPointTop.vue"),
  },
  data() {
    return {
      showTopCopper: false,
      showTopSoldermask: false,
      showTopSilkscreen: false,
      showBottomCopper: false,
      showBottomSoldermask: false,
      showBottomSilkscreen: false,
      showMechanicalLayer: [],
      showDrillLayer: [],
      showTestPoints: false,
      showGuidePins: false,
      showPressurePins: false,
      mechanicalColor: [
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
        "#0277BD",
        "#4527A0",
        "#FF5722",
        "#795548",
        "#37474F",
      ],
      scale: 1,
      config2d: {
        stackupScale: 1000,
        inchToMm: 25.4,
        isUnitMm: null,
        pressureTip: 1,
        pcbHeight: null,
        pcbWidth: null,
        topSvgWidth: null,
        topLayerExist: null,
        bottomLayerExist: null,
        viewBox: [0, 0, 0, 0],
      },
      btnRenderSide: 0,
      btnRenderLayer: null,
      xMultiplier: 0,
      yMultiplier: 0,
      gerberConfig: {
        originX: null,
        originY: null,
        width: null,
        height: null,
        transform: null,
        translate: null,
        scale: null,
        transformAtt: null,
        viewBox: null,
      },
      showAllTop: true,
      showAllBottom: true,
      showAllMechanical: true,
      showAllDrill: true,
      snack: false,
      showAllMechanicalLayers: false,
      showAllDrillLayers: false,
    };
  },
  computed: {
    shownAllTop() {
      return (
        this.showTopCopper && this.showTopSoldermask && this.showTopSilkscreen
      );
    },
    hiddenAllTop() {
      return (
        !this.showTopCopper &&
        !this.showTopSoldermask &&
        !this.showTopSilkscreen
      );
    },
    shownAllBottom() {
      return (
        this.showBottomCopper &&
        this.showBottomSoldermask &&
        this.showBottomSilkscreen
      );
    },
    hiddenAllBottom() {
      return (
        !this.showottomCopper &&
        !this.showBottomSoldermask &&
        !this.showBottomSilkscreen
      );
    },
    shownAllFF() {
      return this.showTestPoints;
    },
    hiddenAllFF() {
      return !this.showTestPoints;
    },
    hoverText() {
      return this.$store.getters["ui/hoverText"];
    },
  },
  methods: {
    shownAllList(items) {
      return items.every((element) => element === true);
    },
    hiddenAllList(items) {
      return items.every((element) => element === false);
    },
    initLayers() {
      //Top Copper Layer Rendering
      if (this.topCopperContainer?.svg) {
        const topCopperContent = new DOMParser().parseFromString(
          this.topCopperContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(topCopperContent);
        this.$refs.TopCopper.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < topCopperContent.children.length; i++) {
          this.$refs.TopCopper.innerHTML +=
            topCopperContent.children[i].innerHTML;
        }
        this.showTopCopper = true;
        console.log("Top Copper", this.$refs.TopCopper.innerHTML);
      }

      //Top Soldermask Layer Rendering
      if (this.topSoldermaskContainer?.svg) {
        const topSoldermaskContent = new DOMParser().parseFromString(
          this.topSoldermaskContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(topSoldermaskContent);
        this.$refs.TopSoldermask.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < topSoldermaskContent.children.length; i++) {
          this.$refs.TopSoldermask.innerHTML +=
            topSoldermaskContent.children[i].innerHTML;
        }
        this.showTopSoldermask = true;
      }

      //Top Silkscreen Layer Rendering
      if (this.topSilkscreenContainer?.svg) {
        const topSilkscreenContent = new DOMParser().parseFromString(
          this.topSilkscreenContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(topSilkscreenContent);
        this.$refs.TopSilkscreen.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < topSilkscreenContent.children.length; i++) {
          this.$refs.TopSilkscreen.innerHTML +=
            topSilkscreenContent.children[i].innerHTML;
        }
        this.showTopSilkscreen = true;
      }

      //Bottom Copper Layer Rendering
      if (this.bottomCopperContainer?.svg) {
        const bottomCopperContent = new DOMParser().parseFromString(
          this.bottomCopperContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(bottomCopperContent);
        this.$refs.BottomCopper.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < bottomCopperContent.children.length; i++) {
          this.$refs.BottomCopper.innerHTML +=
            bottomCopperContent.children[i].innerHTML;
        }
        this.showBottomCopper = true;
      }

      //Bottom Soldermask Layer Rendering
      if (this.bottomSoldermaskContainer?.svg) {
        const bottomSoldermaskContent = new DOMParser().parseFromString(
          this.bottomSoldermaskContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(bottomSoldermaskContent);
        this.$refs.BottomSoldermask.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < bottomSoldermaskContent.children.length; i++) {
          this.$refs.BottomSoldermask.innerHTML +=
            bottomSoldermaskContent.children[i].innerHTML;
        }
        this.showBottomSoldermask = true;
      }

      //Bottom Silkscreen Layer Rendering
      if (this.bottomSilkscreenContainer?.svg) {
        const bottomSilkscreenContent = new DOMParser().parseFromString(
          this.bottomSilkscreenContainer.svg,
          "image/svg+xml"
        ).documentElement;
        !this.gerberConfig.transform &&
          this.initializeViewBox(bottomSilkscreenContent);
        this.$refs.BottomSilkscreen.setAttribute(
          "transform",
          this.gerberConfig.transform
        );
        for (let i = 0; i < bottomSilkscreenContent.children.length; i++) {
          this.$refs.BottomSilkscreen.innerHTML +=
            bottomSilkscreenContent.children[i].innerHTML;
        }
        this.showBottomSilkscreen = true;
      }

      //Mechanical Layers Render
      if (
        this.allMechanicalContainer &&
        this.allMechanicalContainer?.length > 0
      ) {
        let childs,
          mechanical = [];
        for (const [i, v] of this.allMechanicalContainer.entries()) {
          console.log(i, v);
          mechanical[i] = new DOMParser().parseFromString(
            v.svg,
            "image/svg+xml"
          ).documentElement;
          !this.gerberConfig.transform && this.initializeViewBox(mechanical[i]);
          console.log(`Mechanical${i}`, mechanical[i]);
          childs = mechanical[i].children.length > 1;
          for (let x = 0; x < mechanical[i].children.length; x++) {
            this.$refs.allMechanicalContainer[i].innerHTML +=
              mechanical[i].children[x].innerHTML;
          }
          if (
            mechanical[i].children[childs ? "1" : "0"].hasAttribute("transform")
          ) {
            console.log(
              `TRANSFORM ${i}`,
              mechanical[i].children[childs ? "1" : "0"]
                .getAttribute("transform")
                .split(" ")[0]
                .split(",")[1]
                .split(")")[0]
            );
            this.$refs.allMechanicalContainer[i].setAttribute(
              "transform",
              parseInt(
                mechanical[i].children[childs ? "1" : "0"]
                  .getAttribute("transform")
                  .split(" ")[0]
                  .split(",")[1]
                  .split(")")[0]
              ) <=
                parseInt(
                  this.gerberConfig.translate.split(",")[1].split(")")[0]
                )
                ? mechanical[i].children[0].hasAttribute("transform")
                  ? mechanical[i].children[0].getAttribute("transform")
                  : this.gerberConfig.transform
                : this.gerberConfig.transform
            );
          }
          this[`showMechanicalLayer`].splice(i, 1, true);
        }
        if (mechanical[mechanical.length - 1].hasAttribute("viewBox")) {
          const mechVB = mechanical[mechanical.length - 1].getAttribute(
            "viewBox"
          );
          if (
            parseInt(mechVB.split(" ")[2]) >
            parseInt(this.gerberConfig.viewBox.split(" ")[2])
          ) {
            const newVB0 =
              parseInt(mechVB.split(" ")[0]) <
              parseInt(this.gerberConfig.viewBox.split(" ")[0])
                ? mechVB.split(" ")[0]
                : this.gerberConfig.viewBox.split(" ")[0];
            const newVB1 =
              parseInt(mechVB.split(" ")[1]) <
              parseInt(this.gerberConfig.viewBox.split(" ")[1])
                ? mechVB.split(" ")[1]
                : this.gerberConfig.viewBox.split(" ")[1];
            const newVB2 =
              parseInt(mechVB.split(" ")[2]) >
              parseInt(this.gerberConfig.viewBox.split(" ")[2])
                ? mechVB.split(" ")[2]
                : this.gerberConfig.viewBox.split(" ")[2];
            const newVB3 =
              parseInt(mechVB.split(" ")[3]) >
              parseInt(this.gerberConfig.viewBox.split(" ")[3])
                ? mechVB.split(" ")[3]
                : this.gerberConfig.viewBox.split(" ")[3];
            this.$refs.TopSVG.setAttribute(
              "viewBox",
              `${newVB0} ${newVB1} ${newVB2} ${newVB3}`
            );
          }
        }
      }

      //Drill Layers Render
      if (this.allDrillContainer && this.allDrillContainer?.length > 0) {
        let childs,
          drill = [];
        for (const [i, v] of this.allDrillContainer.entries()) {
          console.log(i, v);
          drill[i] = new DOMParser().parseFromString(
            v.svg,
            "image/svg+xml"
          ).documentElement;
          !this.gerberConfig.transform && this.initializeViewBox(drill[i]);
          console.log(`Drill${i}`, drill[i], this.gerberConfig);
          childs = drill[i].children.length > 1;
          for (let x = 0; x < drill[i].children.length; x++) {
            this.$refs.allDrillContainer[i].innerHTML +=
              drill[i].children[x].innerHTML;
          }
          if (drill[i].children[childs ? "1" : "0"].hasAttribute("transform")) {
            console.log(
              `TRANSFORM ${i}`,
              drill[i].children[childs ? "1" : "0"]
                .getAttribute("transform")
                .split(" ")[0]
                .split(",")[1]
                .split(")")[0]
            );
            this.$refs.allDrillContainer[i].setAttribute(
              "transform",
              parseInt(
                drill[i].children[childs ? "1" : "0"]
                  .getAttribute("transform")
                  .split(" ")[0]
                  .split(",")[1]
                  .split(")")[0]
              ) <=
                parseInt(
                  this.gerberConfig.translate.split(",")[1].split(")")[0]
                )
                ? drill[i].children[0].hasAttribute("transform")
                  ? drill[i].children[0].getAttribute("transform")
                  : this.gerberConfig.transform
                : this.gerberConfig.transform
            );
          }
          this[`showDrillLayer`].splice(i, 1, true);
        }
        if (drill[drill.length - 1].hasAttribute("viewBox")) {
          const drillVB = drill[drill.length - 1].getAttribute("viewBox");
          if (
            parseInt(drillVB.split(" ")[2]) >
            parseInt(this.gerberConfig.viewBox.split(" ")[2])
          ) {
            const newVB0 =
              parseInt(drillVB.split(" ")[0]) <
              parseInt(this.gerberConfig.viewBox.split(" ")[0])
                ? drillVB.split(" ")[0]
                : this.gerberConfig.viewBox.split(" ")[0];
            const newVB1 =
              parseInt(drillVB.split(" ")[1]) <
              parseInt(this.gerberConfig.viewBox.split(" ")[1])
                ? drillVB.split(" ")[1]
                : this.gerberConfig.viewBox.split(" ")[1];
            const newVB2 =
              parseInt(drillVB.split(" ")[2]) >
              parseInt(this.gerberConfig.viewBox.split(" ")[2])
                ? drillVB.split(" ")[2]
                : this.gerberConfig.viewBox.split(" ")[2];
            const newVB3 =
              parseInt(drillVB.split(" ")[3]) >
              parseInt(this.gerberConfig.viewBox.split(" ")[3])
                ? drillVB.split(" ")[3]
                : this.gerberConfig.viewBox.split(" ")[3];
            this.$refs.TopSVG.setAttribute(
              "viewBox",
              `${newVB0} ${newVB1} ${newVB2} ${newVB3}`
            );
          }
        }
      }
      this.calculateScale(
        this.$refs.TopSVG.getAttribute("viewBox").split(" ")[2]
      );
      this.initConfig();
    },
    toggleMechanical({ props, index }) {
      this[`${props}`].splice(index, 1, !this[`${props}`][index]);
    },
    toggleColor({ props, index }) {
      return this[props][index] ? this.mechanicalColor[index] : "";
    },
    toggleIcon({ props, index }) {
      return this[props][index] ? "mdi-eye" : "mdi-eye-off";
    },
    toggleDark({ props, index }) {
      return this[props][index] ? true : false;
    },
    calculateScale(viewbox) {
      setTimeout(() => {
        let rootRect = this.$refs.TopSVG.getBoundingClientRect();
        this.scale = viewbox / rootRect?.width;
      }, 100);
    },
    initConfig() {
      this.config2d.pressureTip = 1000 / this.config2d.inchToMm;
      this.config2d.topSvgWidth =
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[2]) /
        1000;
      this.config2d.isUnitMm = false;
      this.config2d.pcbHeight =
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[3]) /
        1000;
      this.config2d.pcbWidth =
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[2]) /
        1000;
      this.config2d.viewBox = [
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[0]),
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[1]),
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[2]),
        parseFloat(this.$refs.TopSVG.getAttribute("viewBox").split(" ")[3]),
      ];
      console.log(this.config2d);
    },
    transformRender(selection) {
      console.log(selection, this.gerberConfig);
      let transformAtt, translateX, translateY, scaleX, scaleY;
      translateX = this.gerberConfig.translate.split(",")[0].split("(")[1];
      translateY = this.gerberConfig.translate.split(",")[1].split(")")[0];
      scaleX = this.gerberConfig.scale.split(",")[0].split("(")[1];
      scaleY = this.gerberConfig.scale.split(",")[1].split(")")[0];
      if (!selection || selection == 0) {
        transformAtt = this.gerberConfig.transform;
      } else if (selection == 1) {
        transformAtt = `translate(${parseInt(this.gerberConfig.width) +
          parseInt(this.gerberConfig.originX) *
            2}, ${translateY}) scale(${scaleX * -1}, ${scaleY})`;
      } else if (selection == 2) {
        transformAtt = `translate(0,0) scale(1,1)`;
      }
      console.log(
        "Transformer",
        transformAtt,
        translateX,
        translateY,
        scaleX,
        scaleY
      );
      this.gerberConfig.transformAtt = transformAtt;
      this.$refs.TopCopper.setAttribute("transform", transformAtt);
      this.$refs.TopSoldermask.setAttribute("transform", transformAtt);
      this.$refs.TopSilkscreen.setAttribute("transform", transformAtt);
      this.$refs.BottomCopper.setAttribute("transform", transformAtt);
      this.$refs.BottomSoldermask.setAttribute("transform", transformAtt);
      this.$refs.BottomSilkscreen.setAttribute("transform", transformAtt);
      this.transformMechanicalLayer({
        items: this.allMechanicalContainer,
        transformAtt,
      });
      this.transformDrillLayer({ items: this.allDrillContainer, transformAtt });
      this.btnRenderLayer = null;
    },
    transformLayer(selection) {
      console.log(selection, this.gerberConfig);
      let transformAtt, translateX, translateY, scaleX, scaleY;
      translateX = this.gerberConfig.translate.split(",")[0].split("(")[1];
      translateY = this.gerberConfig.translate.split(",")[1].split(")")[0];
      scaleX = this.gerberConfig.scale.split(",")[0].split("(")[1];
      scaleY = this.gerberConfig.scale.split(",")[1].split(")")[0];
      if (!selection || selection == 0) {
        this.svgToggler({ layer: "Top", isShown: true });
        this.svgToggler({ layer: "Bottom", isShown: false });
        transformAtt = this.gerberConfig.transform;
      } else {
        this.svgToggler({ layer: "Top", isShown: false });
        this.svgToggler({ layer: "Bottom", isShown: true });
        transformAtt = `translate(${parseInt(this.gerberConfig.width) +
          parseInt(this.gerberConfig.originX) *
            2}, ${translateY}) scale(${scaleX * -1}, ${scaleY})`;
      }
      console.log(
        "Transformer",
        transformAtt,
        translateX,
        translateY,
        scaleX,
        scaleY
      );
      this.gerberConfig.transformAtt = transformAtt;
      this.$refs.TopCopper.setAttribute("transform", transformAtt);
      this.$refs.TopSoldermask.setAttribute("transform", transformAtt);
      this.$refs.TopSilkscreen.setAttribute("transform", transformAtt);
      this.$refs.BottomCopper.setAttribute("transform", transformAtt);
      this.$refs.BottomSoldermask.setAttribute("transform", transformAtt);
      this.$refs.BottomSilkscreen.setAttribute("transform", transformAtt);
      this.transformMechanicalLayer({
        items: this.allMechanicalContainer,
        transformAtt,
      });
      this.transformDrillLayer({ items: this.allDrillContainer, transformAtt });
      this.btnRenderSide = null;
    },
    transformMechanicalLayer({ items, transformAtt }) {
      for (let i = 0; i < items.length; i++) {
        this.$refs.allMechanicalContainer[i].setAttribute(
          "transform",
          transformAtt
        );
      }
    },
    transformDrillLayer({ items, transformAtt }) {
      for (let i = 0; i < items.length; i++) {
        this.$refs.allDrillContainer[i].setAttribute("transform", transformAtt);
      }
    },
    svgToggler({ layer, isShown }) {
      this[`show${layer}Copper`] = isShown;
      this[`show${layer}Soldermask`] = isShown;
      this[`show${layer}Silkscreen`] = isShown;
    },
    mechanicalToggler({ layer, isShown }) {
      for (let i = 0; i < this.allMechanicalContainer.length; i++) {
        console.log(this[`show${layer}`][i]);
        this[`show${layer}`].splice(i, 1, isShown);
      }
    },
    drillToggler({ layer, isShown }) {
      for (let i = 0; i < this.allDrillContainer.length; i++) {
        this[`show${layer}`].splice(i, 1, isShown);
      }
    },
    ffToggler({ layer, isShown }) {
      this[`show${layer}`] = isShown;
    },
    drToggler({ layer, isShown }) {
      this[`show${layer}`] = isShown;
    },
    allLayersToggler({ isShown }) {
      this.svgToggler({ layer: "Top", isShown });
      this.svgToggler({ layer: "Bottom", isShown });
      this.mechanicalToggler({ layer: "MechanicalLayer", isShown });
      this.drillToggler({ layer: "DrillLayer", isShown });
      this.ffToggler({ layer: "TestPoints", isShown });
    },
    initializeViewBox(content) {
      this.gerberConfig.viewBox = content.getAttribute("viewBox");
      this.gerberConfig.originX = this.gerberConfig.viewBox.split(" ")[0];
      this.gerberConfig.originY = this.gerberConfig.viewBox.split(" ")[1];
      this.gerberConfig.width = this.gerberConfig.viewBox.split(" ")[2];
      this.gerberConfig.height = this.gerberConfig.viewBox.split(" ")[3];
      this.$refs.TopSVG.removeAttribute("width");
      this.$refs.TopSVG.removeAttribute("height");
      console.log(this.gerberConfig.viewBox);
      this.$refs.TopSVG.setAttribute("viewBox", this.gerberConfig.viewBox);
      console.log(content);
      this.gerberConfig.transform =
        content.children.length > 1
          ? content.children[1].getAttribute("transform")
          : content.children[0].getAttribute("transform");
      this.gerberConfig.translate =
        content.children.length > 1
          ? content.children[1].getAttribute("transform").split(" ")[0]
          : content.children[0].getAttribute("transform").split(" ")[0];
      this.gerberConfig.scale =
        content.children.length > 1
          ? content.children[1].getAttribute("transform").split(" ")[1]
          : content.children[0].getAttribute("transform").split(" ")[1];
    },
    initRender() {
      this.$refs.testpointTop &&
        this.$refs.testpointTop.activateDragAndDrop(true);
    },
    toggleHoverText(payload) {
      this.snack = payload;
    },
    getMechanicalColor(index) {
      return this.mechanicalColor[index];
    },
    getVShow({ index, layer }) {
      return (
        this[`all${layer}Container`].length > index &&
        this[`show${layer}Layer`][index]
      );
    },
    initToggleProps({ items, propsName }) {
      for (let i = 0; i < items.length; i++) {
        this[propsName].push(false);
      }
    },
  },
  async mounted() {
    this.allMechanicalContainer.length > 0 &&
      this.initToggleProps({
        items: this.allMechanicalContainer,
        propsName: "showMechanicalLayer",
      });
    this.initLayers();
    if (this.testPoints.length > 0) this.showTestPoints = true;
  },
};
</script>
